<!-- 底栏 -->
<template>
  <div class="box">
    <ul>
      <li >关于我们</li>
      <li >法律声明</li>
      <li >隐私政策</li>
      <li >廉政举报</li>
      <li >联系我们</li>
      <li >加入我们</li>
    </ul>
    <p>© 2009-2019 smartai.com 版权所有 ICP证：45456566</p>
    <p>公网安备 436435455号</p>
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  watch: {

  },
  methods: {

  },
  components: {

  },
};
</script>

<style scoped lang="scss">
  ul{
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    li{
      list-style: none;
      padding: 0 20px;
      color: #999999;
      font-size: 14px;
    }
    li:hover{
      cursor: pointer;
      color: #FA640A;
    }
  }
  p{
    padding: 0;
    margin: 0;
    text-align: center;
    color: #CCCCCC;
    cursor: pointer;
    font-size: 14px;
    margin: 10px 0;
  }
  p:last-child{
    padding: 0 0 30px 0;
  }

</style>
