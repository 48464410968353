<template>
  <div>
    <div class="forth">
      <div class="center">
        <h1>为您推荐</h1>
        <h2>划线价格为产品历史销售标价而非原价</h2>
        <div class="bottom">
          <div class="change">
            <div class="left">
              <img
                src="../../../public/image/home/编组 13备份@3x.png"
                alt=""
                style="width: 40px; height: 40px"
              />
            </div>
            <div class="right">
              <img
                src="../../../public/image/home/编组 13@3x.png"
                alt=""
                style="width: 40px; height: 40px"
              />
            </div>
          </div>
          <el-card
            shadow="hover"
            class="chil"
            v-for="(item, index) in recommendsData"
            :key="index"
          >
            <h1>{{ item.title }}</h1>
            <b>{{ item.label }}</b>
            <h2>{{ item.intro }}</h2>
            <hr />
            <h2>购买时长</h2>
            <h3>{{ item.duration }}年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>{{ item.quantity }}</h3>
            <hr />
            <h4>￥{{ item.price }}<span>/年</span></h4>
            <h5>￥{{ item.oldPrice }}</h5>
            <div class="down">
              <div class="go">立即购买</div>
              <div class="in">加入购物车</div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recommendsData: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.forth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 600px;
  background: #f2f2f2;
  .center {
    width: 1440px;
    height: 484px;
    position: relative;
    top: 0;
    left: 0;
    h1 {
      font-size: 30px;
      line-height: 42px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 6px;
      font-weight: 600;
      font-family: PingFangSC-Medium, sans-serif;
      margin: 0;
    }
    h2 {
      font-size: 14px;
      line-height: 23px;
      font-weight: 500;
      margin: 0;
      color: rgba(0, 0, 0, 0.9);
    }
    h4 {
      margin: 0;
    }
    h5 {
      margin: 0;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 23px;
      .chil {
        width: 270px;
        height: 390px;
        background: #fff;
        padding: 30px 16px;
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        h1:first-child {
          font-size: 18px;
          line-height: 25px;
          color: rgba(0, 0, 0, 0.9);
        }
        b {
          position: absolute;
          top: 32px;
          right: 16px;
          font-size: 12px;
          line-height: 21px;
          color: #ff680d;
          width: 40px;
          height: 21px;
          border: 1px solid #ff680d;
          text-align: center;
          font-weight: 500;
          font-family: PingFangSC-Medium, sans-serif;
        }
        hr {
          width: 100%;
          height: 1px;
          border: none;
          background: rgba(0, 0, 0, 0.1);
          margin: 10px 0 5px 0;
        }
        h2 {
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.5);
          font-weight: 500;
        }
        h2:nth-child(3) {
          line-height: 20px;
          width: 180px;
        }
        h3 {
          font-size: 16px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.9);
          margin-top: 2px;
        }
        h4 {
          font-size: 20px;
          line-height: 28px;
          color: #ff680d;
          margin-top: 5px;
          font-weight: 600;
          span {
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            padding-left: 7px;
          }
        }
        h5 {
          text-decoration: line-through;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.2);
          font-weight: 600;
        }
        .down {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 62px;
          background: #f9f9f9;
          padding: 0 14px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            width: 100px;
            height: 38px;
            font-size: 16px;
            line-height: 38px;
            // background: #FF680D;
            text-align: center;
            cursor: pointer;
            color: #ff680d;
            border: 1px solid #ff680d;
            font-family: PingFangSC-Medium, sans-serif;
            // font-weight: 550;
          }
          .in {
            background: #f9f9f9;
            color: rgba(0, 0, 0, 0.9);
            border: none;
          }
          .go {
            background: #fff;
            color: #ff680d;
            border: 1px solid #ff680d;
          }
          .go:hover {
            background-color: #ff680d;
            color: #fff;
          }
        }
      }
      .chil:nth-of-type(n + 5) {
        b {
          color: #2564fb;
          border: 1px solid #2564fb;
        }
      }
      .change {
        display: flex;
        position: absolute;
        top: 21px;
        right: 0;
        div {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        :first-child {
          margin-right: 18px;
        }
      }
    }
  }
}
</style>
