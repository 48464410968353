<!-- 轮播图 -->
<template>
  <div>
    <div class="banner">
      <div class="block" style="width: 100%">
        <el-carousel trigger="click" height="650px">
          <el-carousel-item v-for="(item, index) in bannerImgs" :key="index">
            <div
              class="banner_img"
              :style="item.style"
              style="width: 100%; height: 650px"
            >
              <div class="banner_center">
                <h2>{{ item.title1 }}</h2>
                <h3 :style="{ color: item.style.color }">
                  {{ item.title2 }}
                </h3>
                <div class="button">
                  <span class="go">立即购买</span>
                  <span>> ></span>
                  <!-- <span class="cry">申请使用</span> -->
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      bannerImgs: [
        {
          style: {
            backgroundImage:
              "url(" +
              require("../../../public/image/home/bannerImg/banner1.png") +
              ")",
            backgroundRepeat: "no-repeat",
            color: "#000000",
          },
          title1: "SmartAI智慧工厂系统",
          title2:
            "实现生产流程的自动优化，赋能企业快速实现智能化改造，数字赋能企业生产自动化只能化改造升级，抢抓新旧动能转换重大工程机遇，加快生产制造的自动化、智能化建设",
          info: "立即抢购",
        },
        {
          style: {
            backgroundImage:
              "url(" +
              require("../../../public/image/home/bannerImg/banner2.png") +
              ")",
            backgroundRepeat: "no-repeat",
            color: "#ffffff",
          },
          title1: "WMS智能仓储管理系统",
          title2: "满足不同行业不同经营模式的智能仓储管理系统",
          info: "立即抢购",
        },
        {
          style: {
            backgroundImage:
              "url(" +
              require("../../../public/image/home/bannerImg/banner3.png") +
              ")",
            backgroundRepeat: "no-repeat",
            color: "#000000",
          },
          title1: "一站式全行业数智型CRM",
          title2: "赋能各行业实现一体化数字化转型",
          info: "立即体验",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-carousel__button {
  background: #ccc;
  height: 5px;
}
::v-deep .is-active .el-carousel__button{
  background: #ff680d;
}
::v-deep .el-carousel{
  position: relative;
}
::v-deep .el-carousel__indicators {
  position: absolute;
  left: 13%;
  top: 500px;
  margin-left: 50px;
}
.banner {
  position: relative;
  width: 100%;
  height: 650px;
  .block {
    width: 100%;
    .banner_img {
      width: 100%;
      height: 650px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .banner_center {
        width: 600px;
        padding-top: 200px;
        padding-left: 13%;
        h2 {
          font-size: 40px;
          font-weight: normal;
          margin: 0;
        }
        h3 {
          width: 630px;
          font-size: 14px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.9);
          font-weight: 500;
          overflow: hidden;
        }
        .button {
          color: #ff680d;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
