/<template>
  <div id="app">
    <Header></Header>
    <!-- 轮播 -->
    <div style="clear: both">
      <CarouselImg></CarouselImg>
    </div>
    <!-- 售前咨询 -->
    <div class="third">
      <div class="center">
        <div>
          <img src="../../../public/image/home/位图@3x(1).png" alt="" />
          <h1>售前咨询</h1>
          <h2>
            致电010-84155885，领取您的专属优惠权益实现生产流程的自动化，赋能企业快速实现智能化改造
          </h2>
        </div>
        <hr />
        <div>
          <img src="../../../public/image/home/位图@3x(2).png" alt="" />
          <h1>使用中心</h1>
          <h2>
            算法免费试用，为您提供 0
            门槛上云实践机会，轻松上手，手把手引导试用产品
          </h2>
        </div>
        <hr />

        <div>
          <img src="../../../public/image/home/位图@3x(3).png" alt="" />
          <h1>售后无忧</h1>
          <h2>
            提供全方位的售后包装服务，保护中国近40%网站，根据2021年Gartner报告，安全能力全球第一
          </h2>
        </div>
      </div>
    </div>
    <!-- 为您推荐 -->
    <!-- <div>
      <Recommends :recommendsData="recommendsData"></Recommends>
    </div> -->
    <!-- 热门产品 -->
    <div class="fif">
      <div class="center">
        <h1>热门产品</h1>
        <h2>划线价格为产品历史销售标价而非原价</h2>
        <!-- 商品卡片 -->
        <GoodsCard :cardData="cardData"></GoodsCard>
      </div>
    </div>
    <!-- AI爆款 -->
    <!-- <div class="sis">
      <div class="center">
        <h1>AI爆款</h1>
        <h2>精心挑选的热门算法及应用，总有一款适合你</h2>
        <ul>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="seven">
      <!-- <div class="center">
        <h1>管理中心</h1>
        <h2>性价比超高的管理中心，刚需产品一键购齐，不仅省钱还省心</h2>
        <ul>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
          <li class="chil">
            <h1>设备故障检测</h1>
            <b>算法</b>
            <h2>
              可实现产品质量管理、数据管理、质量控制和误差分析等功能，包括溯源和
              统计、数据挖掘等功能
            </h2>
            <hr />
            <h2>购买时长</h2>
            <h3>1年</h3>
            <hr />
            <h2>购买数量</h2>
            <h3>1</h3>
            <hr />
            <h4>￥ 977<span>/3年</span></h4>
            <h5>￥1200.00</h5>
            <div class="down">
              <div class="go" style="background-color: #ff680d; color: #fff">
                立即购买
              </div>
              <div class="in">加入购物车</div>
            </div>
          </li>
        </ul>
      </div> -->

      <!-- 联系我们 -->
      <ContactUs></ContactUs>
    </div>
    <!-- footer -->
    <BottomRail></BottomRail>
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import GoodsCard from "@/views/goodsCard/index.vue"; //热门产品
import CarouselImg from "@/components/carouselImg/index.vue"; //轮播图
import BottomRail from "@/components/bottomRail/index.vue"; // 底栏
import ContactUs from "@/components/contactUs/index.vue"; // 联系我们
import Recommends from "@/views/recommend/index.vue"; //为您推荐
export default {
  name: "HomePage",
  components: {
    Header,
    CarouselImg,
    GoodsCard,
    BottomRail,
    ContactUs,
    Recommends,
  },
  props: {},
  data() {
    return {
      activeName: "first",
      parentMessage: "Hello from parent component",
      show3: true,
      // 热门产品
      cardData: [
        {
          goodsRightShow: 1,
          haveMindTo: true, //咨询、购买
          title: "SmartAI智慧工厂系统",
          brief: "一键购买，实现企业智能化改造",
          imgSrc: "箭头.png",
          goodsVersions: "智慧工厂系统",
          introduce:
            "生产排程系统是一种用于优化生产流程和资源利用的工具，它可以帮助制造企业提高生产效率、降低成本，并满足客户需求。通过将排程结果下发到设备上的智能触摸屏终端，工厂可以实时监控生产过程中并调整生产计划",
          trait: [
            "过程监控",
            "数据可视化",
            "历史朔源",
            "预测性分析",
            "优化提升",
          ],
        },
        {
          goodsRightShow: 1,
          haveMindTo: true,
          title: "CRM客户管理系统",
          brief: "赋能各行业实现一体化数字化转型",
          imgSrc: "箭头.png",
          goodsVersions: "CRM客户管理系统",
          introduce:
            "系统以数字内容为基础，以数据驱动为核心，通过实现全场景和数据互通，为线上经营管理、客户服务需求提供一站式技术服务。助力企业完成数字化管理升级，规范化管理流程。根据系统对客户信息的整理统筹与分配，从而提高销售效率减少人力上的消耗，防止员工离职导致客户流失，促进团队合作，达到企业降本增效的目的。",
          trait: ["市场营销自动化", "销售过程自动化", "客户服务自动化"],
        },
        {
          goodsRightShow: 1,
          haveMindTo: true,
          title: "WMS智能仓储管理系统",
          brief: "满足不同行业不同经营模式的智能仓储管理系统",
          imgSrc: "箭头.png",
          goodsVersions: "WMS系统",
          introduce:
            "通过wms仓库管理系统，可以有效处理现代物流信息，在很大程度上为管理层决策提供了有效支持，其功能特点应涵盖支持多仓库、多货主，多业务模式，可配置的灵活策略和流程增值服务，系统以产品信息和订单业务作为数据流动的基础，并且要对仓库进行智能分析与监控、简洁、直管、可视化，并跳出传统仓库流程框架，持续发力落实数字化转型，是企业对仓库智能化变革的核心重点。",
          trait: [
            "多层次管理",
            "业务防呆机制",
            "货品细化管理",
            "出入库环节控制",
            "分批管理调度业务",
          ],
        },
        {
          series: [
            {
              goodsVersions: "VisionLink智能终端",
              introduce:
                "通过在每个工序的设备或机台合适位置，安装触摸智能终端设备，用于将排产任务下发至每一个智能终端屏幕。",
              trait: [
                "建⽴设备台账、资产档案，掌握设备整个周期的动态信息",
                "收集设备使⽤过程中的各种信息数据，⾃动汇总运算，生成各类专业的设备管理报表",
              ],
              Configure: [
                " 英特尔 志强 Silver 4114 10核处理器 x 2",
                "64GB 内存 (16GB x 4) + 480GB SSD 存储 + 8TB HDD 存储",
                "千兆 LAN x 4",
              ],
              imgSrc: "projectImg3.png",
            },
            {
              goodsVersions: "LE-V-S003 高性能边缘计算主机",
              introduce:
                "专为高性能视频应用打造，强悍的双志强处理器，轻松应对高算力应用场景的需求。",
              trait: [
                "搭载两颗高性能志强处理器，可提供高达64T的凶猛算力",
                "Server系列，具备单台与集群服务",
                "支持算力虚拟化管控，高稳定性单机热备",
              ],
              Configure: [
                "英特尔 志强 Silver 4114 10核处理器 x 2",
                "64GB 内存 (16GB x 4) + 480GB SSD 存储 + 8TB HDD 存储",
                "千兆 LAN x 4",
              ],
              imgSrc: "projectImg4.png",
            },
          ],
          goodsRightShow: 2,
          haveMindTo: true,
          title: "VisionLink（智能终端）",
          brief:
            "智能终端通过AI视频检测采集数据及PLC采集生产数据。智能终端采集的实时生产数据传递给算法，算法结合数据不断调整优化生产任务及分配策略。",
          imgSrc: "箭头.png",
        },
      ],
      // 为您推荐
      recommendsData: [
        {
          title: "设备故障检测",
          label: "算法",
          intro: "支持通过检测设备故障原因及提供故障预测和状态检测等",
          duration: 1,
          quantity: 1,
          price: "540.00",
          oldPrice: "900.00",
        },
        {
          title: "设备故障检测",
          label: "算法",
          intro: "支持通过检测设备故障原因及提供故障预测和状态检测等",
          duration: 1,
          quantity: 1,
          price: "540.00",
          oldPrice: "900.00",
        },
        {
          title: "设备故障检测",
          label: "算法",
          intro: "支持通过检测设备故障原因及提供故障预测和状态检测等",
          duration: 1,
          quantity: 1,
          price: "540.00",
          oldPrice: "900.00",
        },
        {
          title: "设备故障检测",
          label: "应用",
          intro: "支持通过检测设备故障原因及提供故障预测和状态检测等",
          duration: 1,
          quantity: 1,
          price: "540.00",
          oldPrice: "900.00",
        },
        {
          title: "设备故障检测",
          label: "应用",
          intro: "支持通过检测设备故障原因及提供故障预测和状态检测等",
          duration: 1,
          quantity: 1,
          price: "540.00",
          oldPrice: "900.00",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style  lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC;
}
.center {
  width: 75%;
  margin: auto;
}
::v-deep .el-tabs__item {
  width: 200px;
}

#app {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  .banner {
    height: 650px;
    background-image: url(../../../public/image/home/VCG211377759802@3x.png);
    background-repeat: no-repeat;
    // background: #ccc;
    background-size: cover;
    display: flex;
    align-items: center;
    .center {
      height: 334px;
      h2 {
        font-size: 40px;
        line-height: 56px;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 4px;
        font-family: PingFangSC-Medium, sans-serif;
        font-weight: 500;
      }
      h3 {
        width: 630px;
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 500;
        overflow: hidden;
      }
      .button {
        margin-top: 53px;
        span {
          display: inline-block;
          width: 120px;
          height: 44px;
          line-height: 44px;
          background-color: #ff680d;
          border: 2px solid #ff680d;
          font-size: 16px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-family: PingFangSC-Medium, sans-serif;
        }
        .cry {
          color: #ff680d;
          background: #fff;
          margin-left: 16px;
        }
      }
      ul {
        display: flex;
        margin-top: 126px;
        padding: 0;
        li {
          list-style: none;
          width: 30px;
          height: 4px;
          background: #dbdde7;
          margin-right: 10px;
          cursor: pointer;
        }
        :first-child {
          background: #ff680d;
        }
      }
    }
  }
  .third {
    height: 295px;
    width: 100%;
    background: #fff;
    .center {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      hr {
        width: 1px;
        height: 159px;
        background: #d9d9d9;
        border: none;
      }
      div {
        width: 278px;
        height: 227px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 88px;
          height: 88px;
        }
        h1 {
          font-size: 22px;
          line-height: 30px;
          color: rgba(0, 0, 0, 0.9);
          margin-top: 30px;
          font-family: PingFangSC-Medium, sans-serif;
          font-weight: 600;
        }
        h2 {
          font-size: 14px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
  .forth {
    display: flex;
    align-items: center;

    width: 100%;
    height: 600px;
    background: #f2f2f2;
    .center {
      height: 484px;
      position: relative;
      top: 0;
      left: 0;
      > h1 {
        font-size: 30px;
        line-height: 42px;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 6px;
        font-weight: 600;
        font-family: PingFangSC-Medium, sans-serif;
      }
      > h2 {
        font-size: 14px;
        line-height: 23px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 23px;
        .chil {
          width: 270px;
          height: 390px;
          background: #fff;
          padding: 30px 16px;
          box-sizing: border-box;
          position: relative;
          top: 0;
          left: 0;
          > h1:first-child {
            font-size: 18px;
            line-height: 25px;
            color: rgba(0, 0, 0, 0.9);
          }
          > b {
            position: absolute;
            top: 32px;
            right: 16px;
            font-size: 12px;
            line-height: 21px;
            color: #ff680d;
            width: 40px;
            height: 21px;
            border: 1px solid #ff680d;
            text-align: center;
            font-weight: 500;
            font-family: PingFangSC-Medium, sans-serif;
          }
          hr {
            width: 100%;
            height: 1px;
            border: none;
            background: rgba(0, 0, 0, 0.1);
            margin: 10px 0 5px 0;
          }
          > h2 {
            font-size: 12px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 500;
          }
          > h2:nth-child(3) {
            line-height: 20px;
            width: 180px;
          }
          > h3 {
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            margin-top: 2px;
          }
          h4 {
            font-size: 20px;
            line-height: 28px;
            color: #ff680d;
            margin-top: 5px;
            font-weight: 600;
            span {
              color: rgba(0, 0, 0, 0.6);
              font-size: 18px;
              padding-left: 7px;
            }
          }
          > h5 {
            text-decoration: line-through;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.2);
            font-weight: 600;
          }
          > .down {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 62px;
            background: #f9f9f9;
            padding: 0 14px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              width: 100px;
              height: 38px;
              font-size: 16px;
              line-height: 38px;
              // background: #FF680D;
              text-align: center;
              cursor: pointer;
              color: #ff680d;
              border: 1px solid #ff680d;
              font-family: PingFangSC-Medium, sans-serif;
              // font-weight: 550;
            }
            .in {
              background: #f9f9f9;
              color: rgba(0, 0, 0, 0.9);
              border: none;
            }
          }
        }
        .change {
          display: flex;
          position: absolute;
          top: 21px;
          right: 0;
          > div {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
          :first-child {
            margin-right: 18px;
          }
        }
      }
    }
  }
  .fif {
    width: 100%;
    background: #ffffff;
    .center {
      margin-top: 80px;
      > h1 {
        font-size: 30px;
        line-height: 42px;
        color: rgba(0, 0, 0, 0.9);
      }
      > h2 {
        margin-top: 6px;
        margin-bottom: 48px;
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 500;
      }
      .pic {
        width: 100%;
        height: 600px;
        margin-bottom: 68px;
        display: flex;
        .left {
          width: 20%;
          height: 100%;
          box-sizing: border-box;
          padding: 53px 23px;
          background: #fa640a;
          position: relative;
          left: 0;
          top: 0;
          h1 {
            font-size: 30px;
            line-height: 42px;
            color: #fff;
          }
          h2 {
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            margin-top: 30px;
            font-weight: 500;
          }
          img {
            width: 69px;
            height: 12.38px;
            position: absolute;
            left: 23px;
            bottom: 98px;
          }
        }
        .right {
          flex: 1;
          box-sizing: border-box;
          padding-left: 59px;
          padding-top: 50px;
          border: 1px solid #e1e1e1;
          position: relative;
          left: 0;
          top: 0;
          background-image: url(../../../public/image/home/编组\ 10@3x.png);
          background-size: 620px 340px;
          background-repeat: no-repeat;
          background-position-x: right;
          background-position-y: bottom;

          .top {
            margin-top: -20px;
            width: 364px;
            height: 46px;
            margin-bottom: 14px;
            display: flex;
            div {
              width: 50%;
              height: 100%;
              background: #fff;
              font-size: 16px;
              line-height: 46px;
              font-weight: 600;
              box-sizing: border-box;
              text-align: center;
              cursor: pointer;
            }
            :first-child {
              border-top: 3px solid #ff680d;
            }
            :last-child {
              font-weight: 500;
            }
          }
          h1 {
            font-size: 20px;
            line-height: 28x;
            color: rgba(0, 0, 0, 0.9);
            margin-bottom: 12px;
          }
          h2 {
            font-size: 14px;
            line-height: 20px;
            width: 93%;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 500;
            margin-bottom: 24px;
          }
          ul {
            padding: 0;
            position: absolute;
            left: 59px;
            bottom: 48px;
            div {
              font-size: 20px;
              line-height: 28px;
              color: #ff680d;
              margin-bottom: 16px;
            }
            li {
              font-size: 15px;
              line-height: 21px;
              color: rgba(0, 0, 0, 0.9);
              margin-bottom: 19px;
              padding-left: 29px;
              list-style: none;
              background-image: url(../../../public/image/home/对勾.png);
              background-size: 22px 22px;
              background-repeat: no-repeat;
              background-position-x: left;
              background-position-y: top;
              // list-style-image: url(../../../public/image/home/对勾.png);
            }
          }
        }
        .boot {
          background-image: url(../../../public/image/home/位图@3x\(4\).png);
          background-color: #f9f9f8;
          background-size: 354px 497px;
        }
      }
    }
  }
  .sis {
    background: #fff;
    .center {
      > h1 {
        font-size: 30px;
        line-height: 42px;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 6px;
      }
      > h2 {
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: normal;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 48px;
        li {
          list-style: none;
          box-sizing: border-box;
          border: 1px solid #e7e7e7;
          border-top: 7px solid #ff680d;
          width: 30%;
          height: 396px;
          margin-bottom: 30px;
          background: #fff;
          padding: 30px 16px;
          box-sizing: border-box;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          position: relative;
          top: 0;
          left: 0;
          > h1:first-child {
            font-size: 18px;
            line-height: 25px;
            color: rgba(0, 0, 0, 0.9);
          }
          > b {
            position: absolute;
            top: 32px;
            right: 16px;
            font-size: 12px;
            line-height: 21px;
            color: #ff680d;
            width: 40px;
            height: 21px;
            border: 1px solid #ff680d;
            text-align: center;
            font-weight: 500;
          }
          hr {
            width: 100%;
            height: 1px;
            border: none;
            background: rgba(0, 0, 0, 0.1);
            margin: 10px 0 5px 0;
          }
          > h2 {
            font-size: 12px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 500;
          }
          > h2:nth-child(3) {
            line-height: 20px;
            width: 95%;
          }
          > h3 {
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            margin-top: 2px;
          }
          h4 {
            font-size: 20px;
            line-height: 28px;
            color: #ff680d;
            margin-top: 5px;
            font-weight: 600;
            span {
              color: rgba(0, 0, 0, 0.6);
              font-size: 18px;
              padding-left: 7px;
            }
          }
          > h5 {
            text-decoration: line-through;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.2);
            font-weight: 600;
          }
          > .down {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 62px;
            background: #f9f9f9;
            padding: 0 14px;
            box-sizing: border-box;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            > div {
              width: 100px;
              height: 38px;
              font-size: 16px;
              line-height: 38px;
              // background: #FF680D;
              text-align: center;
              cursor: pointer;
              color: #ff680d;
              border: 1px solid #ff680d;
            }
            .in {
              background: #f9f9f9;
              color: rgba(0, 0, 0, 0.9);
              border: none;
              margin-left: 6px;
            }
          }
        }
        .change {
          display: flex;
          position: absolute;
          top: 21px;
          right: 0;
          :first-child {
            margin-right: 18px;
          }
        }
      }
    }
  }
  .seven {
    // height: 636px;
    background: #f2f2f2;
    .center {
      padding-top: 48px;
      > h1 {
        font-size: 30px;
        line-height: 42px;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 6px;
      }
      > h2 {
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: normal;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 48px;
        li {
          list-style: none;
          box-sizing: border-box;
          border: 1px solid #e7e7e7;
          border-top: 7px solid #ff680d;
          width: 30%;
          height: 396px;
          margin-bottom: 30px;
          background: #fff;
          padding: 30px 16px;
          box-sizing: border-box;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          position: relative;
          top: 0;
          left: 0;
          > h1:first-child {
            font-size: 18px;
            line-height: 25px;
            color: rgba(0, 0, 0, 0.9);
          }
          > b {
            position: absolute;
            top: 32px;
            right: 16px;
            font-size: 12px;
            line-height: 21px;
            color: #ff680d;
            width: 40px;
            height: 21px;
            border: 1px solid #ff680d;
            text-align: center;
            font-weight: 500;
          }
          hr {
            width: 100%;
            height: 1px;
            border: none;
            background: rgba(0, 0, 0, 0.1);
            margin: 10px 0 5px 0;
          }
          > h2 {
            font-size: 12px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 500;
          }
          > h2:nth-child(3) {
            line-height: 20px;
            width: 95%;
          }
          > h3 {
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            margin-top: 2px;
          }
          h4 {
            font-size: 20px;
            line-height: 28px;
            color: #ff680d;
            margin-top: 5px;
            font-weight: 600;
            span {
              color: rgba(0, 0, 0, 0.6);
              font-size: 18px;
              padding-left: 7px;
            }
          }
          > h5 {
            text-decoration: line-through;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.2);
            font-weight: 600;
          }
          > .down {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 62px;
            background: #f9f9f9;
            padding: 0 14px;
            box-sizing: border-box;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            > div {
              width: 100px;
              height: 38px;
              font-size: 16px;
              line-height: 38px;
              // background: #FF680D;
              text-align: center;
              cursor: pointer;
              color: #ff680d;
              border: 1px solid #ff680d;
            }
            .in {
              background: #f9f9f9;
              color: rgba(0, 0, 0, 0.9);
              border: none;
              margin-left: 6px;
            }
          }
        }
        .change {
          display: flex;
          position: absolute;
          top: 21px;
          right: 0;
          :first-child {
            margin-right: 18px;
          }
        }
      }
    }
  }
  .eight {
    height: 202px;
    .center {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        width: 434px;
        justify-content: space-between;
        li {
          list-style: none;
          a {
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            text-decoration: none;
          }
        }
      }
      span {
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 13px;
      }
    }
  }
}
</style>