import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/reset.css'

Vue.config.productionTip = false
Vue.use(ElementUI);
window.document.title = "首页";

new Vue({
  render: h => h(App),
}).$mount('#app')
