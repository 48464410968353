<!-- 商品卡片-goodsCard -->
<template>
  <div>
    <template>
      <div v-for="(item, index) in cardData" :key="index" class="goods_card">
        <div class="goods_card_left">
          <h1>{{ item.title }}</h1>
          <p>{{ item.brief }}</p>
          <img
            :src="require(`../../../public/image/home/${item.imgSrc}`)"
            alt=""
          />
        </div>
        <div
          v-show="item.goodsRightShow === 1"
          class="goods_card_right card_height"
        >
          <h1>{{ item.goodsVersions }}</h1>
          <h2>
            {{ item.introduce }}
          </h2>
          <div class="button" v-show="item.haveMindTo">
            <div class="go">立即购买</div>
            <div class="in">立即咨询</div>
          </div>
          <div class="project">
            <ul>
              <div style="margin-top: 50px">产品特点</div>
              <div>
                <li v-for="(item1, index1) in item.trait" :key="index1">
                  {{ item1 }}
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div
          v-show="item.goodsRightShow === 2"
          class="goods_card_tab card_height"
        >
          <div class="right boot">
            <div class="top">
              <div @click="tab1">智慧终端系列1</div>
              <div @click="tab2">智慧终端系列2</div>
            </div>
            <el-carousel 
            trigger="click"
            ref="carouselpage"
            arrow="never">
              <el-carousel-item v-for="(itemPage,indexpage) in terminalData" :key="indexpage" >
            <h2>
              {{itemPage.info}}
            </h2>
            <div class="button">
              <div class="go">立即购买</div>
              <div class="in">立即咨询</div>
            </div>
            <div style="display:flex;align-items:center;justify-content:space-between">
            <div class="project">
              <ul>
                <div style="color: #fa640a" >产品优势</div>
                <li v-for="(advantage,advantages) in itemPage.advantage" :key="advantages">{{advantage}}</li>
                
                <div style="margin-top: 37px; color: #fa640a">配置</div>
                <li v-for="disposition in itemPage.disposition " :key="disposition">{{disposition}}</li>
              </ul>
            </div>
            <div style="width:500px;height:300px;margin-right:50px;">
              <img :src="require(`../../../public/image/home/${itemPage.imgSrc}`)" alt="" style="width:500px">
            </div>
          </div>
            
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div
          v-show="item.goodsRightShow === 3"
          class="goods_card_right goods_ai"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  // props: {},
  props: ["cardData"],
  data() {
    return {
      activeName: "first",
      goodsRightShow: false, //是否展示右侧信息栏，false:tab页不显示
      isBtnClass: false,
      terminalData:[
        {
          info:"通过在每个工序的设备或机台合适位置，安装触摸智能终端设备，用于将排产任务下发至每一个智能终端屏幕。",
          advantage:["建⽴设备台账、资产档案，掌握设备整个周期的动态信息","收集设备使⽤过程中的各种信息数据，⾃动汇总运算，生成各类专业的设备管理报表"],
          disposition:["英特尔 志强 Silver 4114 10核处理器 x 2","64GB 内存 (16GB x 4) + 480GB SSD 存储 + 8TB HDD 存储","千兆 LAN x 4"],
          imgSrc:"Group.png"
        },
        {
          info:" 搭载两颗高性能志强处理器，可提供高达64T的凶猛算力",
          advantage:[" Server系列，具备单台与集群服务","支持算力虚拟化管控，高稳定性单机热备"],
          disposition:["英特尔 志强 Silver 4114 10核处理器 x 2","64GB 内存 (16GB x 4) + 480GB SSD 存储 + 8TB HDD 存储","千兆 LAN x 4"],
          imgSrc:"crm.png"
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    tab1() {
      // console.log(this.$refs, "xxx");
      this.$refs.carouselpage[3].setActiveItem(0);
      
    },
    tab2() {
      this.$refs.carouselpage[3].setActiveItem(1);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs__header {
  height: 40px;
  margin: 0;
  text-align: center;

  .el-tabs__item.is-active {
    color: #ff680d;
  }
  .el-tabs__item:hover {
    color: #ff680d;
  }
  .el-tabs__item {
    color: #222;
  }
}
::v-deep .el-tabs__content {
  height: 360px;
  .el-tab-pane {
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
    .pane_left {
      h4 {
        margin: 5px 0;
      }
      p {
        margin: 0;
      }
    }
  }
}
::v-deep .el-carousel {
  // border-right: 1px solid #e1e1e1;
  // border-bottom: 1px solid #e1e1e1;
  .el-carousel__indicators {
    display: none;
  }
  .el-carousel__container {
    height: 500px;
  }
  .pane_box_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .pane_left {
      width: 60%;
      box-sizing: border-box;
      padding: 0px 20px 0px 20px;
      font-size: 14px;
      ul {
        padding: 0;
        margin: 0;
        div {
          margin: 0;
          padding: 0;
        }
        li {
          color: #999999 !important;
          font-size: 13px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.9);
          padding-left: 29px;
          list-style: none;
          background-image: url(../../../public/image/home/对勾.png);
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position-x: left;
          background-position-y: top;
        }
        li:hover {
          color: #000 !important;
          cursor: pointer;
        }
      }
      div {
        border-bottom: 0.5px solid #ccc;
        padding: 10px 0;
      }
      .el-button {
        border-radius: 0px;
        width: 120px;
        height: 44px;
        background: #ff680d;
        color: #fff;
        font-size: 16px;
      }
      .tinge {
        color: #999;
        padding: 0;
        margin: 0;
      }
      h4 {
        color: #333;
        margin: 5px 0;
      }
    }
    .pane_right {
      width: 40%;
      text-align: center;
      img {
        width: 300px;
        // height: 200px;
      }
    }
  }
}
::v-deep .el-card__body {
  padding: 0;
  height: 400px;
}

::v-deep .goods_card_left:last-of-type() {
  height: 800px !important;
  min-height: 800px;
  max-height: 900px;
}
.goods_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  margin-bottom: 70px;
  cursor: pointer;
  .goods_card_left {
    width: 20%;
    min-height: 600px;
    max-height: 700px;
    height: 100%;
    box-sizing: border-box;
    padding: 43px 23px;
    background: #fa640a;
    position: relative;
    left: 0;
    top: 0;
    h1 {
      font-size: 26px;
      color: #fff;
      text-align: left;
    }
    p {
      margin-top: 50px;
      font-size: 14px;
      color: #fff;
    }
    img {
      width: 69px;
      height: 12.38px;
      position: absolute;
      left: 23px;
      bottom: 98px;
    }
  }
  .goods_card_right {
    height: 400px;
    box-sizing: border-box;
    flex: 1;
    box-sizing: border-box;
    padding-left: 59px;
    padding-top: 50px;
    border: 1px solid #e1e1e1;
    position: relative;
    left: 0;
    top: 0;

    h1 {
      font-size: 20px;
      line-height: 28x;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 12px;
    }
    h2 {
      font-size: 14px;
      line-height: 20px;
      width: 93%;
      color: #999;
      font-weight: 500;
      margin-bottom: 24px;
    }
    .button {
      display: flex;
      div {
        width: 120px;
        height: 44px;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        border: 2px solid #ff680d;
        cursor: pointer;
        background: #ff680d;
        color: #fff;
        // background: #fff;
        // color: #ff680d;
      }
      // div:hover{
      //   background: #ff680d;
      //   color: #fff;
      // }
      .in {
        background: #fff;
        color: #ff680d;
        margin-left: 16px;
      }
    }
    .project {
      width: 100%;
      display: flex;
      justify-content: left;
      overflow: hidden;
      ul {
        display: flex;
        flex-wrap: wrap;
        // margin-top: 55px;
        padding: 0px;
        max-width: 500px;
        div {
          font-size: 20px;
          line-height: 28px;
          color: #999999;
          margin-bottom: 16px;
          width: 100%;
        }
        li {
          color: #999999 !important;
          font-size: 15px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.9);
          margin-bottom: 19px;
          padding-left: 29px;
          padding-right: 5px;
          list-style: none;
          background-image: url(../../../public/image/home/对勾.png);
          background-size: 22px 22px;
          background-repeat: no-repeat;
          background-position-x: left;
          background-position-y: top;
        }
        li:hover {
          color: #000 !important;
          cursor: pointer;
        }
      }
      .project_img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 300px;
          height: 180px;
          padding-right: 70px;
          cursor: pointer;
        }
      }
    }
  }
  .management {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0;
    border: none;
    height: 400px;
    .el-card {
      width: 33.3%;
      border-radius: 0;
      height: 400px;
      position: relative;
      .box_card_top {
        width: 100%;
        padding: 20px;
        height: 75%;
        box-sizing: border-box;
        h4 {
          margin: 0;
          font-weight: normal;
          font-size: 16px;
        }
        p {
          font-size: 14px;
          color: #828282;
          padding: 0;
          margin: 0;
        }
        p:nth-of-type(1) {
          font-size: 14px;
          color: #828282;
          padding: 5px 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-top: 15px;
          padding: 0px;
          max-width: 500px;
          div {
            font-size: 14px;
            line-height: 28px;
            color: #999999;
            width: 100%;
          }
          li {
            color: #999999 !important;
            font-size: 14px;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.9);
            margin-bottom: 19px;
            padding-left: 29px;
            padding-right: 5px;
            list-style: none;
            background-image: url(../../../public/image/home/对勾.png);
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position-x: left;
            background-position-y: top;
            // list-style-image: url(../../../public/image/home/对勾.png);
          }
          li:hover {
            color: #000 !important;
            cursor: pointer;
          }
        }
      }

      .box_card_bottom {
        padding: 20px;
        height: 25%;
        box-sizing: border-box;
        p {
          margin: 0;
        }
        .new_price_box {
          span:nth-of-type(2) {
            font-size: 24px;
          }
          span:nth-of-type(-n + 3) {
            color: #ff6a00;
          }
        }
        .old_price_box {
          color: #d6d6d6;
        }
      }
      .bottom_show {
        padding: 0;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        // display: none;
        transition: height 0.3s ease-in-out;
        .el-button {
          display: block;
          width: 100%;
          height: 100px;
          height: 0;
          background: #fa640a;
          font-size: 18px;
          color: #fff;
        }
      }
    }
    .el-card:hover {
      .box_card_bottom {
        height: 100px;
        display: none;
        .el-button {
          height: 100%;
        }
      }
      .bottom_show {
        display: block;
      }
    }
  }
  .goods_card_tab {
    height: 100%;
    box-sizing: border-box;
    flex: 1;
    padding: 0;
    .pane_top {
      width: 100%;
      box-sizing: border-box;
      // border-right: 1px solid #E4E7ED ;
      border-bottom: 1px solid #e4e7ed;
      .el-button {
        border-bottom: none;
        border-radius: 0;
      }
      .el-button:hover {
        color: #fa640a;
      }
    }
    .el-tabs__content {
      height: 200px !important;
      .pane_box {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 100%;
        overflow: hidden;
      }
    }
  }
}
.goods_card:nth-of-type(1) {
  .goods_card_right {
    background-image: url(../../../public/image/home/编组\ 10@3x.png);
    background-size: 718.2px 453.6px;
    background-repeat: no-repeat;
    background-position: 90% 60%;
  }
}
.goods_card:nth-of-type(2) {
  .goods_card_right {
    background-image: url(../../../public/image/home/crm.png);
    background-size: 576px 327px;
    background-repeat: no-repeat;
    background-position: 90% 60%;
  }
}
.goods_card:nth-of-type(3) {
  .goods_card_right {
    background-image: url(../../../public/image/home/wms.png);
    background-size: 576px 327px;
    background-repeat: no-repeat;
    background-position: 90% 60%;
  }
}
.goods_card:nth-of-type(4) .goods_card_left {
  height: 600px;
}
.goods_card:nth-of-type(4) .goods_card_right {
  height: 600px;
  border: none;
  box-sizing: border-box;
  padding: 0;
}
.right {
  flex: 1;
  box-sizing: border-box;
  padding-left: 59px;
  padding-top: 50px;
  border: 1px solid #e1e1e1;
  position: relative;
  left: 0;
  top: 0;
  // background-image: url(../../../public/image/home/编组\ 10@3x.png);
  background-size: 620px 340px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;

  .top {
    margin-top: -20px;
    width: 364px;
    height: 46px;
    margin-bottom: 14px;
    display: flex;
    div {
      width: 50%;
      height: 100%;
      background: #fff;
      font-size: 16px;
      line-height: 46px;
      font-weight: 600;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
    }
    :first-child {
      border-top: 3px solid #ff680d;
    }
    :last-child {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 20px;
    line-height: 28x;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
  }
  h2 {
    font-size: 14px;
    line-height: 20px;
    width: 93%;
    color: #999;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .button {
    display: flex;
    div {
      width: 120px;
      height: 44px;
      background: #ff680d;
      font-size: 16px;
      line-height: 44px;
      color: #fff;
      text-align: center;
      border: 2px solid #ff680d;
      cursor: pointer;
    }
    .in {
      background: #fff;
      color: #ff680d;
      margin-left: 16px;
    }
  }
  ul {
    list-style: none;
    width: 400px;
    li {
      list-style: none;
      word-wrap: break-word;
    }
  }
  .project {
    width: 300px !important;
    display: block;
    ul {
      padding: 0;
      // position: absolute;
      bottom: 48px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
      margin-top: 20px;
      // width: 100%;
      div {
        font-size: 20px;
        line-height: 28px;
        color: #999999;
        margin-bottom: 16px;
        width: 100%;
      }
      li {
        color: #999999 !important;
        font-size: 15px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 19px;
        padding-left: 29px;
        padding-right: 5px;
        list-style: none;
        background-image: url(../../../public/image/home/对勾.png);
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
      }
      li:hover {
        color: #000 !important;
        cursor: pointer;
      }
    }
    .project_img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 300px;
        height: 180px;
        padding-right: 70px;
        cursor: pointer;
      }
    }
  }
}
// .boot {
//   background-image: url(../../../public/image/home/Group.png);
//   background-color: #f9f9f8;
//   background-size: 533.1px 324px;
//   background-position: 90% 55%;
// }
.card_height {
  height: 600px !important;
  .right {
    height: 600px;
  }
}
</style>
