<!-- 联系我们 -->
<template id="top" >
  <div class="call_me_wrap" v-show="isShow">
    <el-popover placement="left">
      <div class="contact_information">
        <div
          class="contact_top"
          style="display: flex; align-items: center; justify-content: left"
        >
          <div class="telephone_left">
            <i class="el-icon-phone-outline" style="font-size:26px;padding-right:20px"></i>
          </div>
          <div class="telephone_right" style="cursor: pointer;">
            <h4>售前咨询电话</h4>
            <p style="color: #fa640a">010-84155885</p>
          </div>
        </div>
        <div
          class="contact_top"
          style="display: flex; align-items: center; justify-content: left;padding-bottom:30px;margin-top:30px"
        >
          <div class="telephone_left">
            <i class="el-icon-edit-outline" style="font-size:26px;padding-right:20px"></i>
          </div>
          <div class="telephone_right"  style="cursor: pointer;">
            <h4>聆听 · 建议反馈</h4>
            <p >贝思科不是完美的，我们渴望您的建议</p>
          </div>
        </div>
      </div>
      <el-button slot="reference" @click="visible = !visible" :aria-hidden="true">
        <i class="el-icon-chat-square"></i>
        <p>联</p>
        <p>系</p>
        <p>我</p>
        <p>们</p>
      </el-button>
    </el-popover>
    <a href="#top">
      <div class="go_top">
        <i class="el-icon-arrow-up"></i>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      visible: false,
      isShow: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= window.innerHeight) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    });
  },
  watch: {},
  methods: {
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
::v-deep .el-button {
  flex: 1;
  display: block;
  height: 140px;
  width: 40px;
  padding: 0;
  white-space: normal;
  box-sizing: border-box;
  font-size: 16px;
  color: #fa640a ;
  margin: 0 auto;
  background: #FFFFFF;
  i {
    font-size: 20px;
  }
  p {
    padding: 5px;
  }

  // span{
  //   display: block;
  //   text-align: center;
  //   width: 30px;
  // }
}
::v-deep .el-button:hover{
  background: #FA640A;
  color: #fff;
}
.call_me_wrap {
  position: fixed;
  right: 20px; /* 设置元素距离顶部的距离 */
  bottom: 180px; /* 设置元素距离左侧的距离 */
  width: 40px;
  height: 200px;
  .go_top {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    text-align: center;
    line-height: 40px;
    border: 0.5px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    i {
      font-size: 20px;
      color: #fa640a;
      font-weight: bold;
    }
  }
}
</style>
