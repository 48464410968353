<template>
  <div id="header_wrap">
    <div class="header">
      <div class="center">
        <div class="topleft">
          <img src="../../../public/image/home/位图@3x.png" alt="" />
          <input type="text" placeholder="智慧工厂" />
          <span>搜索</span>
        </div>
        <div class="topright">
          <a href="">首页</a>
          <a :href="link">智慧工厂</a>
          <a href="">管理中心</a>
          <a href="">算法</a>
          <a href="">应用</a>
          <a href="">管理服务</a>
          <span class="right-btn"><a :href="link">登录</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import util from "@/libs/util.js";
// import Cookies from "js-cookie";
export default {
  props: {},
  data() {
    return {
      link:""
    };
  },
  computed: {},
  created() {
    this.link=this.getApsPage()
  },
  mounted() {},
  watch: {},
  methods: {
    environmentType(){
      let type
      if (location.href.includes('192.168.20.119')) {
        type = 'test'
      } else if (location.href.includes('192.168') || location.href.includes('localhost')) {
        type = 'dev'
      } else {
        type = 'prod'
      }
      return type
    },
    getApsPage(){
      // let token = util.cookies.get("token");
      // Cookies.set("token", token, { expires: 7, path: "/" });
      // console.log(util.cookies.get("token"),'==token')
      // window.open(`//aps.fai365.com:${getPort()}${this.startPage}`); ${window.location.hostname}
      const loginPathMap = {
        prod:`//aps.fai365.com:9080`,
        test:`//192.168.20.119:9080`,
        dev: `//192.168.8.117:8080`
      }
      return loginPathMap[this.environmentType()]
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#header_wrap {
  margin: 0 auto;
  .header {
    // width: 1920px;
    width: 100%;
    height: 94px;
    display: flex;
    align-items: center;
    background: #181f30;
    // background: pink;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);
    .center {
      margin: 0 auto;
      width: 75%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .topleft {
        width: 675px;
        display: flex;
        align-items: center;
        img {
          width: 106.63px;
          height: 22px;
          margin-right: 40px;
        }
        input {
          width: 413px;
          height: 44px;
          box-sizing: border-box;
          padding-left: 20px;
          border: 1.5px solid #ff680d;
          outline: none;
          box-shadow: none;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 10px;
        }
        input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
        span {
          text-align: center;
          width: 104px;
          height: 44px;
          background: #ff680d;
          border-color: #ff680d;
          box-shadow: none;
          font-size: 16px;
          line-height: 44px;
          color: #fff;
          cursor: pointer;
          font-family: PingFangSC-Medium, sans-serif;
        }
      }
      .topright {
        width: 533px;
        height: 44px;
        line-height: 44px;
        .right-btn{
          margin-left:40px;
        }
        a {
          margin-left: 40px;
          font-size: 14px;
          // color: rgba(0, 0, 0, 0.9);
          color: #ffffff !important;
          text-decoration: none;
          font-family: PingFangSC-Medium, sans-serif;
        }
        a:hover {
          color: #ff680d !important;
        }
        :first-child {
          margin-left: 0;
        }
        a:hover,
        a:visited,
        a:link,
        a:active {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}
</style>
